import React from 'react'
import './index.css'

function About() {
  return (
    <div className="about" id='aboutUs'>
        <p>Pan City Medical Center</p>
        <hr />
        <div className="abt-container">
            <div className="about-us div">
                <h1>About <span>Us</span></h1>
                <div className="des">
                    <p>
                        PanCity Medical Clinic is located in Embakasi East Constituency, Nairobi-Kenya. 
                    </p>
                    <p>
                        Established in 2009 to provide preventive, curative, counselling and referral
                        services to the people of Embakasi and its surrounding areas.
                    </p>
                    <p>
                        It is run by a Visiting doctor, resident clinical officer, Nurse/Midwife and two general staff.
                    </p>
                </div>
            </div>
            <div className="values div">
                <h1>Our <span>values</span></h1>
                <div className="des">
                    <ol>
                        <li>Safety in everything we do.</li>
                        <li>Our patients are our focus.</li>
                        <li>We care for the environment.</li>
                        <li>Our people are our strength.</li>
                        <li>We act ethically and professionally.</li>
                    </ol>
                </div>
            </div>
            <div className="vision div">
                <h1>our <span>vision</span></h1>
                <div className="des">
                    <p>
                        To provide professional medical care to the community of Embakasi and surrounding areas.
                    </p>
                </div>
            </div>

            <div className="mision div">
                <h1>our <span>mission</span></h1>
                <div className="des">
                    <p>
                        Committed to provide the best preventive, curative and referral services 
                    </p>
                </div>
            </div>

            <div className="mision div">
                <h1>our <span>Motto</span></h1>
                <div className="des">
                    <p>
                        To provide Quality Health Care
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About