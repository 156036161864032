import './App.css';
import Banner from './components/heroSection';
import Navbar from './components/navbar';
import Services from './components/services';
import About from './components/about_us'
import Contact from './components/contact';
import Footer from './components/footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <Services />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
