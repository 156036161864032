import React from 'react'
import './index.css'

function Footer() {
  return (
    <div className="footer">
        <p>Copyright © 2022 * Pan City Medical Clinic</p>
    </div>
  )
}

export default Footer