import React from 'react'
import { Link } from 'react-router-dom'
import './index.css';
import { FaPhoneAlt, FaEnvelope  } from "react-icons/fa";


const Modal = ({ isShowing, hide }) => {
  return (
    <>
        {isShowing ? 
            <div className='modal'>
                <div className="modal-container">
                    <div className="modal-title">
                        <h1>
                            ❤️ Thank You For Caring
                        </h1>
                        <hr />
                    </div>
                    <div className="modal-details">
                        Any assistance the you can offer the clinic will be appreciated.
                        To learn on how you can assits the clinic, please call or text:-
                        <div className="cont">
                            <p>
                                <Link to="/">
                                    <FaPhoneAlt />
                                    +254 721 424 903
                                </Link>
                            </p>
                            or
                            <p>
                                <Link to="/">
                                    <FaPhoneAlt />
                                    +254 721 424 903
                                </Link>
                            </p>
                            Send an email to 
                            <p>
                                <Link to="/">
                                    <FaEnvelope />
                                    info@pancityclinic.org
                                </Link>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <div className="btmod" onClick={hide}>
                                Close
                            </div>
                        </div>

                    </div>
                </div>
            </div> : null
        }
    </>
  )
}

export default Modal