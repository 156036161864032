import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import './index.css'
import { useEffect, useState } from 'react'
import Modal from '../modal'
import useModal from '../../utils/useModal'

const Navbar = () => {
  const {isShowing, toggle} = useModal();
  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 40) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <nav className={navbar ? "navbar active" : "navbar"}>
      <Modal 
        isShowing={isShowing}
        hide={toggle}
      />
      <div className="brand">
          <Link to="#home" className="logo">
            <img src={logo} alt="" srcset="" />
          </Link>
          <Link to="#home">Pan City Medical Clinic</Link>
      </div>
      <ul className="navlinks">
        <li className="navlink" onClick={() => window.location.replace("/#home")}>
          <NavLink to="#home">HOME</NavLink>
        </li>
        <li className="navlink" onClick={() => window.location.replace("/#services")}>
          <NavLink to="#services" >SERVICES</NavLink>
        </li>
        <li className="navlink" onClick={() => window.location.replace("/#aboutUs")}>
          <NavLink to="#aboutUs">ABOUT US </NavLink>
        </li>
        <li className="navlink" onClick={() => window.location.replace("/#contact")}>
          <NavLink to="#contact">CONTACT</NavLink>
        </li>
        <li className="navbtn" onClick={toggle}>
          <Link to="/">DONATE</Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar