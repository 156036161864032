import React from 'react';
import './index.css'
 const AddressMap=()=>{
    return (
        <div className="google-map-code">
            <iframe 
                title='map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d342.5251766445033!2d36.917023651107016!3d-1.3052634680104074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1303f85a9389%3A0xbbb6ca7e2c209464!2sMWV8%2BVRJ%2C%20Nairobi!5e0!3m2!1sen!2ske!4v1663195203920!5m2!1sen!2ske" 
                style={{border:0}}
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">

            </iframe>
        </div>
    );
 }
 export{AddressMap}