import React from 'react';
import './index.css';
import image from '../../assets/images/doc_2.jpg'
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <div className="banner" id='home'>
      <div className="banner-container">
        <div className="banner-image">
          <img src={image} alt="pancity clinic doctor treating a patient" srcset="" />
        </div>
        <div className="banner-det">
          <h1>Who Are We?</h1>
          <p>
            We are a health clinic located at the heart of a community that is not well informed of the causes of comminicable diaeases. 
            They still believe in supestitions, witch craft, causes and other beliefs so we need 
            to create awareness through:-
            <ol>
              <li>Individuaul and group counseling</li>
              <li>Out reach programs to renforce covid 19 regulations</li>
              <li>Nutrition education for infants, children under 5</li>
              <li>Encourage hospital delivaries</li>
              <li>Educate on teen pregnancy</li>
            </ol>
          </p>
          <div className="btn">
            <Link to="/">Can I Help ?</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner