import React from 'react';
import './index.css'
import emergency from '../../assets/images/emergency.jpg';
import concelling from '../../assets/images/councelling.jpg';
import maternity from '../../assets/images/maternity.jpg';
import cir from '../../assets/images/circum.png'

function Services() {
  return (
    <div className="services" id='services'>
        <h1>Services</h1>
        <div className="service-items">
            <div className="service-item">
                <img src={emergency} alt="pancity emergency medicine" />
                <div className="details">
                    <h1>Emergency Medicine</h1>
                    <p>
                        We provide a quick response tot all illnesses or injuries requiring immediate medical attention.
                    </p>
                </div>
                
            </div>
            <div className="service-item">
                <img src={concelling} alt="pancity mental care " />
                <div className="details">
                    <h1>Councelling</h1>
                    <p>You dont have to struggle alone we provide a safe space for one to face all our worries</p>
                </div>
                
            </div>
            <div className="service-item">
                <img src={maternity} alt="pancity maternity medicine" />
                <div className="details">
                    <h1>Maternity</h1>
                    <p>
                        Maternal healthcare matters, <br />
                        Newborn health matters
                    </p>
                </div>
                
            </div>
            <div className="service-item">
                <img src={cir} alt="Male Circumcision" />
                <div className="details">
                    <h1>Male Circumcision</h1>
                    <p>
                        At pancity we offer voluntary male circumsion
                    </p>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Services