import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import { AddressMap } from '../map'

function Contact() {
  return (
    <div className="contact" id={'contact'}>
        <div className="contact-container">
            <h1>contact</h1>
            <div className="contact-item">
              <Link to="/">+254 721 424 903</Link>
            </div>
            <div className="contact-item">
            <Link to="/">+254 721 424 903</Link>
            </div>
            <div className="contact-item">
              <Link to="/">info@pancityclinic.org</Link>
            </div>
        </div>
        <div className="map">
            <AddressMap />
        </div>
    </div>
  )
}

export default Contact